import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import WorksOverview from "../components/worksOverview"
import AboutTeaser from "../components/aboutTeaser"

const Works = (props) => {
  return (
    <Layout title="Works" slug="works"> 
      <WorksOverview/>
      <AboutTeaser/>
    </Layout>
  )
}

export default Works
